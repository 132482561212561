<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.auth") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="grid gap-4">
      <div>
        <h2 class="mb-2">{{ t("headings.change_email") }}</h2>
        <div class="card grid p-4">
          <p class="px-2 py-4">{{ t("text.change_email") }}</p>
          <custom-form
            :formElements="emailFormElements"
            formStyle="inline"
            @submitForm="changeEmail"
          />
        </div>
      </div>
      <div>
        <h2 class="mb-2">{{ t("headings.change_password") }}</h2>
        <div class="card grid p-4">
          <p class="px-2 py-4">{{ t("text.change_password") }}</p>
          <custom-form
            :formElements="passwordFormElements"
            formStyle="inline"
            @submitForm="changePassword"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
export default {
  components: {
    Breadcrumb,
    CustomForm,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const emailFormElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "email",
        label: "email_new",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "email",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "email-again",
        label: "email_again",
        rules: ["isRequired"],
        type: "text",
        value: "",
        variable: "emailAgain",
      },
      {
        class: "col-span-12 text-right",
        element: "button",
        id: "email-submit-button",
        label: "save",
        textVariant: "white",
        type: "submit",
        variant: "indigo",
      },
    ]);

    const passwordFormElements = ref([
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "password-new",
        label: "password_new",
        rules: ["isRequired"],
        type: "password",
        value: "",
        variable: "password",
      },
      {
        class: "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
        element: "input",
        id: "password-again",
        label: "password_again",
        match: "password",
        rules: ["isRequired", "isMatch"],
        type: "password",
        value: "",
        variable: "passwordAgain",
      },
      {
        class: "col-span-12 text-right",
        element: "button",
        id: "password-submit-button",
        label: "save",
        textVariant: "white",
        type: "submit",
        variant: "indigo",
      },
    ]);

    return {
      changeEmail: (data) => store.dispatch("authentication/changeEmail", data),
      changePassword: (data) =>
        store.dispatch("authentication/changePassword", data),
      emailFormElements,
      passwordFormElements,
      t,
    };
  },
};
</script>
